@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

* {
    font-family: "Kanit", sans-serif !important;
    font-weight: 400;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
}

a:link {
    color: #000;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

a:visited {
    color: #000;
    text-decoration: none;
}

a:hover {
    color: #2a5478;
    text-decoration: none;
}
X
a .underline:hover {
    text-decoration: underline;
}

a:active {
    color: #000;
    text-decoration: none;
}